import { DetailedProduct } from './ClientProductDetailed';
import { GatewayProductSitemapContainer } from './GatewayProduct';
import { NeededPriceType } from './PriceType';
import { UserManual } from './UserManual';
import { Range } from './Range';
import { Product, ProductWithActiveVariant, SearchLandingPage } from './ClientProduct';
import { NewMoney } from './Money';
import { ValueOf } from '../../helper/utility';

export const CategoryProductSortingOption = {
    Bestseller: 'bestseller',
    PriceASC: 'price.asc',
    PriceDESC: 'price.desc',
    NameASC: 'name.asc',
    NameDESC: 'name.desc',
} as const;

export type CategoryProductSortOption = ValueOf<typeof CategoryProductSortingOption>;

export interface GetCategoryProductsRequest {
    categoryId: string;
    country: string;
    currency: string;
    locale: string;
    limit: number;
    offset: number;
    sort?: CategoryProductSortOption;
}

export interface GetCategoryProductsResponse {
    products: Array<Product>;
    count: number;
    limit: number;
    offset: number;
    total: number;
}

export interface GetProductsByIdRequest {
    ids: string[];
    currency: string;
    locale: string;
    country: string;
    neededPriceType?: NeededPriceType;
    relatedSku?: string;
}

export interface GetProductsResponse {
    products: Product[];
}

export interface GetProductsBySkuRequest {
    country: string;
    currency: string;
    skus: string[];
    locale: string;
    neededPriceType?: NeededPriceType;
    relatedSku?: string;
}

export interface GetProductsByKeyRequest {
    keys: string[];
    currency: string;
    locale: string;
    country: string;
    neededPriceType?: NeededPriceType;
    relatedSku?: string;
}

export interface GetProductBySlugRequest {
    country: string;
    currency: string;
    locale: string;
    neededPriceType?: NeededPriceType;
    relatedSku?: string;
    sku?: string;
    slug: string;
}

export interface GetProductBySlugResponse {
    product: DetailedProduct;
}

export interface GetProductPricesBySkuRequest {
    sku: string;
    country: string;
    neededPriceType: NeededPriceType;
    relatedSku?: string;
}

export interface GetProductPricesBySkuResponse {
    existsOtherThenCheapestCustomerPrices: boolean;
    existsOtherThenCheapestPrices: boolean;
    aboPrice?: NewMoney;
    actualPrice?: NewMoney;
    cheapestCustomerPrice?: NewMoney;
    cheapestPrice?: NewMoney;
    customerPrice?: NewMoney;
    insteadPrice?: NewMoney;
    originalListPrice?: NewMoney;
}

export interface GetSitemapProductsByCategoryRequest {
    country: string;
    currency: string;
    locale: string;
    categoryIds: Array<string>;
    offset: number;
    limit: number;
}

export interface GetSitemapProductsByCategoryResponse {
    products: Array<GatewayProductSitemapContainer>;
    total?: number;
}

export interface GetProductsByRecommendationRequest {
    currency: string;
    locale: string;
    country: string;
    sku?: string;
}

export interface GetProductsByRecommendationResponse {
    products: Product[];
    deepLinks: Record<string, string>;
}

export interface SearchProductsRequest {
    query: string;
    country: string;
    currency: string;
    locale: string;
    offset: number;
    limit: number;
}

export interface SearchProductsResponse {
    products: Array<Product>;
    total: number;
    landingPage: SearchLandingPage;
}

export interface AutoSuggestRequest {
    query: string;
    country: string;
    currency: string;
    locale: string;
    limit?: number;
}

export interface AutoSuggestResponse {
    effectiveQuery?: string;
    keywords: Array<string>;
    landingPage: { name: string; url: string };
    products: Array<Product>;
    totalKeywordCount: number;
    totalProductCount: number;
}

export interface SearchByAttributeFilter {
    attribute: string;
    logicalConjunction: string;
    value?: string;
    range?: Range;
}

export interface SearchByAttributeRequest {
    country: string;
    currency: string;
    filters: Array<SearchByAttributeFilter>;
    limit: number;
    locale: string;
    offset: number;
}

export interface SearchByAttributeResponse {
    products: Array<Product>;
    total: number;
}

export interface GetUserManualsRequest {
    locale: string;
}

export interface GetUserManualsResponse {
    manuals: UserManual[];
}

export interface GetWkoProductsRequest {
    currency: string;
    locale: string;
}

export interface GetWkoProductsResponse {
    wko?: Product;
    upsell?: Product;
    upsellForLineItemKey?: string;
}

export interface QueryProductsRequest {
    identifiers: string[];
    locale: string;
    country: string;
    currency: string;
    neededPriceType?: NeededPriceType;
}

export interface QueryProductsResponse {
    products: {
        [key: string]: ProductWithActiveVariant;
    };
}
