import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { getIsNavItemActive } from '../../helper';
import { MainNavigationCustomNavigationItemProps } from '../../types';
import DesktopSubNavigation from '../DesktopSubNavigation';

const componentName = 'desktop-navigation-item';

export type DesktopNavigationItemProps = BaseProps & {
    isLastItem: boolean;
    navItem: MainNavigationCustomNavigationItemProps;
    onCloseSubNavigation: () => void;
};

const DesktopNavigationItem = ({ isLastItem, navItem, onCloseSubNavigation }: DesktopNavigationItemProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const ref = useRef<HTMLLIElement>(null);

    const [isItemClicked, setIsItemClicked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const hasChildItems = Boolean(navItem.mainNavigationChildItems?.length);

    const isTouchDevice = useMemo(() => {
        return typeof window !== 'undefined' && window.matchMedia('(pointer:coarse)').matches;
    }, []);

    const resetNavItemStates = useCallback(() => {
        setIsItemClicked(false);
        setIsOpen(false);
    }, []);

    const openSubNavigation = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleCloseSubNavigation = useCallback(() => {
        resetNavItemStates();
        onCloseSubNavigation();
    }, [resetNavItemStates]);

    const onLinkClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>) => {
            // check if the device is a touch device
            if (isTouchDevice && hasChildItems) {
                event.preventDefault();
                event.stopPropagation();

                if (isItemClicked) {
                    resetNavItemStates();
                    navigate(navItem.link.reference);
                    onCloseSubNavigation();
                } else {
                    setIsItemClicked(true);
                    setIsOpen(true);
                }
            }
        },
        [isItemClicked, hasChildItems, navItem.link.reference, navigate, onCloseSubNavigation, resetNavItemStates]
    );

    useOutsideClicked(ref, resetNavItemStates);

    useEffect(() => resetNavItemStates(), [pathname, resetNavItemStates]);
    return (
        <li
            ref={ref}
            className={classNames(componentName, {
                [`${componentName}--hover`]: isOpen,
            })}
            onMouseEnter={openSubNavigation}
            onTouchStart={openSubNavigation}
            onMouseLeave={resetNavItemStates}
        >
            <LinkOpenInTab
                className={`${componentName}__link`}
                tab={navItem.tab}
                link={navItem.link}
                onClick={onLinkClick}
                isActive={getIsNavItemActive(navItem.link.reference, pathname)}
            >
                <div className={`${componentName}__link-text`}>
                    {navItem.link.text}
                    {hasChildItems ? <Icon name="ArrowDown" className={`${componentName}__arrow`} /> : SKIP_RENDER}
                </div>
            </LinkOpenInTab>
            {hasChildItems && isOpen && (
                <DesktopSubNavigation
                    isLastItem={isLastItem}
                    subNavigation={navItem.mainNavigationChildItems!}
                    closeSubNavigation={handleCloseSubNavigation}
                />
            )}
        </li>
    );
};

export default DesktopNavigationItem;
