import { Helmet } from 'react-helmet-async';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useLocation } from 'react-router';
import { useBrxComponentContext } from '@mediashop/app/bloomreach/useBrxComponentContext';

export type SeoProps = BaseProps & {
    metaDescription: string;
    title: string;
};

function SeoConfig() {
    const { pathname } = useLocation();

    const component = useBrxComponentContext();
    const { description, title } = component.getProperties();

    if (pathname.includes('/c/')) {
        return SKIP_RENDER;
    }

    return (
        <Helmet>
            {title ? <title>{title}</title> : SKIP_RENDER}

            {description ? <meta name="description" content={description} /> : SKIP_RENDER}
        </Helmet>
    );
}

export default injectComponent('pattern.atom.SeoConfig', SeoConfig);
