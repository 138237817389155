import LinkHelper from '../helper/LinkHelper';
import { Link } from 'react-router';
import { BasePropsWithChildren } from '../bloomreach/types';

type Props = BasePropsWithChildren & {
    href: string;
    target?: string;
    rel?: string;
    onClick?: (event) => void;
};

/*
 * Securely open links in new tab
 * https://mathiasbynens.github.io/rel-noopener/
 */
const secureTargetBlankLinks = (props: Partial<Props>) => {
    if (props.target === '_blank') {
        props.rel = 'noopener';
    }
    return props;
};

/**
 * Handle links coming from bloomreach. These may be relative or absolute urls.
 * URLs staying on the same page will get rendered as Links. Others as a-elements.
 */
export default function BloomreachLink({ href, ...props }: Props): JSX.Element {
    props = secureTargetBlankLinks(props);

    if (LinkHelper.isAbsoluteUrl(href)) {
        return <a {...props} href={href} />;
    }

    return <Link {...props} to={href} />;
}
