import { Category } from '@mediashop/app/api/types/ClientCategory';
import { BaseProps, BrxLink } from '@mediashop/app/bloomreach/types';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import {
    BrxLinkOpenInTab,
    BrxLinkOpenInTabWithColors,
    LinkOpenInTab,
} from '@mediashop/base/pattern/atom/LinkOpenInTab';
import classNames from 'classnames';
import { ReactElement } from 'react';
import DesktopMenuItem from './DesktopMenuItem';
import { componentName as desktopMenuItemComponentName } from '@mediashop/catalog-base/pattern/organism/MainNavigation/DesktopMenu/DesktopMenuItem';
import { useLocation } from 'react-router';

const componentName = 'desktop-menu';

type DesktopMenuProps = BaseProps & {
    extendedItems?: BrxLinkOpenInTab[];
    menuItems: Category[];
    quickLink?: BrxLinkOpenInTabWithColors;
};

const DesktopMenu = ({ className, menuItems, extendedItems, quickLink }: DesktopMenuProps): ReactElement => {
    const { pathname } = useLocation();

    return (
        <nav className={classNames(componentName, className)}>
            <ul>
                {menuItems.map((item) => (
                    <DesktopMenuItem key={item.id} menuItem={item} />
                ))}
                {extendedItems?.map((extendedItem) => (
                    <li
                        key={typeof extendedItem.link === 'object' ? extendedItem.link.text : extendedItem.link}
                        className={desktopMenuItemComponentName}
                    >
                        <LinkOpenInTab
                            className={classNames(`${desktopMenuItemComponentName}__menu-item`, {
                                [`${desktopMenuItemComponentName}__menu-item--active`]:
                                    pathname !== '/' && (extendedItem.link as BrxLink).reference.includes(pathname),
                            })}
                            {...extendedItem}
                        />
                    </li>
                ))}
                {quickLink ? (
                    <li
                        className={classNames(`${componentName}-quicklink`, {
                            [`${componentName}-quicklink--custom-hover`]: quickLink.shadowColorHex,
                        })}
                        style={{
                            background: quickLink.backgroundColorHex ? quickLink.backgroundColorHex : undefined,
                            borderColor: quickLink.shadowColorHex ? quickLink.shadowColorHex : undefined,
                        }}
                    >
                        <LinkOpenInTab
                            className={`${desktopMenuItemComponentName}__menu-item`}
                            {...quickLink}
                            backgroundColorHex={undefined}
                        />
                    </li>
                ) : (
                    SKIP_RENDER
                )}
            </ul>
        </nav>
    );
};
export default DesktopMenu;
