import { Navigate, useBlocker } from 'react-router';
import { SKIP_RENDER } from '../constants/semanticConstants';

const CHECKOUT_SUCCESS_PATHNAME = '/checkoutsuccess';

/**
 * This route interceptor is only used for the checkout-success page and covers the following case:
 * 1. Checkout success page is visible for customer.
 * 2. He now navigates to another location (e.g. click on a category in the main navigation).
 * 3. Then the browser back button is being clicked.
 * 4. We do not want to show the checkout success page again now.
 */
export const RouteInterceptor = (): JSX.Element | null => {
    const blocker = useBlocker(({ nextLocation }) => nextLocation.pathname === CHECKOUT_SUCCESS_PATHNAME);

    return blocker.state === 'blocked' ? <Navigate to="/" /> : SKIP_RENDER;
};
