import { useProduct } from '@mediashop/app/hooks/useProduct';
import BackgroundImage from '@mediashop/base/pattern/molecule/BackgroundImage';
import { useMemo } from 'react';
import classNames from 'classnames';
import { SwiperSlide } from 'swiper/react';
import { injectComponent } from '@mediashop/app/component-injector';
import {
    BaseProps,
    BrxAnchor,
    BrxBackground,
    BrxBackgroundImageWithStyle,
    BrxFilter,
    BrxRelationType,
    BrxSlider,
    ContentBackgroundProps,
} from '@mediashop/app/bloomreach/types';
import Product from '../Product';
import Headlines, { HeadlineProps } from '@mediashop/base/pattern/atom/Headlines';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import BaseUrlHelper from '@mediashop/app/helper/BaseUrlHelper';
import { useLocation } from 'react-router';
import { useProductListAnalytics } from '@mediashop/app/analytics/hooks/useProductListAnalytics';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useSliderProductRelations } from '../../../hooks/useSliderProductRelations';
import useIsClientSide from '@mediashop/app/hooks/useIsClientSide';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { Product as ProductType } from '@mediashop/app/api/types/ClientProduct';
import breakpoints, { BreakpointName } from '@mediashop/app/config/breakpoints';
import { PARTNER_ID_CRM_RECO, PARTNER_ID_CRM_RECO_CART } from '@mediashop/app/constants/partnerIds';
import { sanitizeProductIds } from '@mediashop/app/helper/sanitizeProductIds';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import Slider from '@mediashop/base/pattern/molecule/Slider';
import { StoreProducts } from '@mediashop/app/store/reducer/product';
import { useGetProductBySlug } from '@mediashop/app/hooks/useGetProductBySlug';
import { useSearchByAttribute } from '@mediashop/app/queries/search/useSearchByAttribute';

const NUMBER_OF_SLIDES_TABLET = 3;

const getSlideCountInCurrentViewport = (slidesInViewDesktop: number, viewport: BreakpointName) => {
    if (viewport === 'mobile') {
        return 1;
    } else if (viewport === 'tablet') {
        return NUMBER_OF_SLIDES_TABLET;
    }
    return slidesInViewDesktop;
};

const prepareSlideItemsForDisplay = (products: StoreProducts, cleanProductIDs: string[]) => {
    return Object.entries(products)
        .filter(([id, data]) => cleanProductIDs?.includes(id) && Boolean(data.product))
        .sort((first, sec) => {
            const firstIndex = cleanProductIDs.indexOf(first[0]);
            const secondIndex = cleanProductIDs.indexOf(sec[0]);
            return firstIndex - secondIndex;
        })
        .map(([_, data]) => data);
};

const componentName = 'product-slider';

type ProductSliderProps = BaseProps & {
    anchor?: BrxAnchor;
    headlines?: HeadlineProps;
    background: BrxBackground;
    backgroundImageWithStyle?: BrxBackgroundImageWithStyle;
    contentBackground: ContentBackgroundProps;
    slider?: BrxSlider;
    productIDs: string[];
    roundedImages?: boolean;
    gtmListName?: string;
    productRelations?: BrxRelationType;
    fallbackTitle?: string;
    fallbackSubTitle?: string;
    spacing?: number;
    maxProductCardCountDesktop?: number;
    maxProductCardCountMobile?: number;
    filter?: BrxFilter[];
};

/**
 * Component with a slider for products
 */
// eslint-disable-next-line max-lines-per-function, complexity
function ProductSlider({
    anchor,
    headlines,
    background,
    backgroundImageWithStyle,
    contentBackground,
    roundedImages,
    slider,
    productIDs,
    gtmListName,
    productRelations: relationType,
    fallbackTitle = '',
    fallbackSubTitle,
    spacing,
    maxProductCardCountDesktop,
    maxProductCardCountMobile,
    filter,
}: ProductSliderProps) {
    const currentLocation = useLocation();
    const isClientSide = useIsClientSide();
    const deviceType = useDeviceType();
    const cleanProductIDs = sanitizeProductIds(productIDs);
    const { product: slugProduct } = useGetProductBySlug();

    const filteredProductIds = cleanProductIDs.filter(
        (productID) =>
            !(
                slugProduct?.product?.id === productID ||
                slugProduct?.product?.variants.some(
                    (productVariant) => productVariant.key === productID || productVariant.sku === productID
                )
            )
    );

    const { products } = useProduct();

    const urlObject = BaseUrlHelper.getUrlObject(currentLocation.pathname);
    const slug = urlObject.pathname.replace(/\/$/, '').split('/').pop();
    const crosssellAiContainerId = `product-slider-${slug}`;
    const isCartPage = slug === 'cart';

    let { relatedProducts } = useSliderProductRelations({
        crosssellAiContainerId,
        relationType,
        isCartPage,
    });

    if (relationType === 'crosssell_AI' && !isCartPage) {
        relatedProducts = relatedProducts.filter(
            (product) => product?.product?.variants[0]?.attributes?.isUpsell === false
        );
    }

    const items = useMemo(
        () => prepareSlideItemsForDisplay(products, filteredProductIds),
        [products, filteredProductIds]
    );

    let productList = relatedProducts?.length > 0 ? relatedProducts : items;

    let targetPartnerId: string;
    if (relatedProducts?.length > 0 && relationType === 'crosssell_AI' && isCartPage) {
        targetPartnerId = PARTNER_ID_CRM_RECO_CART;
    } else if (relatedProducts?.length > 0 && relationType === 'crosssell_AI') {
        targetPartnerId = PARTNER_ID_CRM_RECO;
    }

    const slidesInViewDesktop = parseInt(slider?.slidesInViewDesktop ?? '5', 10) || 5;
    const slidesInViewport = getSlideCountInCurrentViewport(slidesInViewDesktop, deviceType);

    /**
     * Analytics (GTM)
     */
    const analyticsProductList = items
        .map((product) => product?.product)
        .filter((product): product is ProductType => Boolean(product));

    useProductListAnalytics(analyticsProductList, gtmListName ?? 'Home_Other');

    const limit = Math.max(maxProductCardCountDesktop ?? 0, maxProductCardCountMobile ?? 0);
    const { data: { products: filteredProducts = [] } = {} } = useSearchByAttribute({
        filters: filter ?? [],
        offset: 0,
        limit: limit > 0 ? limit : 50,
    });

    if (filter && filteredProducts && filteredProducts.length > 0) {
        productList = filteredProducts.map((prod) => {
            return { status: 'succeeded', product: prod };
        });
    }

    const maxSize = deviceType === 'mobile' ? maxProductCardCountMobile : maxProductCardCountDesktop;

    const slicedProductList = maxSize ? productList.slice(0, maxSize) : productList;
    return (
        <BackgroundColorWrapper
            id={anchor?.id}
            backgroundColor={background?.color}
            hexBackgroundColor={background?.backgroundColorHex}
        >
            <BackgroundImage backgroundImageWithStyle={backgroundImageWithStyle}>
                <ContentWrapper>
                    <div className={componentName}>
                        {relatedProducts?.length > 0 && headlines ? (
                            <Headlines {...headlines} className={`${componentName}__headlines`} />
                        ) : (
                            <Headlines
                                {...headlines}
                                headline={fallbackTitle}
                                subheadline={fallbackSubTitle}
                                className={`${componentName}__headlines`}
                            />
                        )}

                        <div
                            id={crosssellAiContainerId}
                            className={classNames(
                                `${componentName}__slider-wrapper`,
                                { [`${componentName}__swiper-server`]: !isClientSide },
                                `${componentName}__slider-wrapper--items-${items.length}`,
                                { [`${componentName}--adjust-slider`]: productList.length > 1 }
                            )}
                        >
                            <Slider
                                className={componentName}
                                spaceBetween={spacing ?? 2}
                                centerInsufficientSlides
                                slidesPerGroup={slidesInViewport}
                                showPagination={deviceType !== 'mobile'}
                                breakpoints={{
                                    [breakpoints[0].minWidth]: {
                                        slidesPerView: 1.7,
                                        centeredSlides: true,
                                        centeredSlidesBounds: productList.length === 1,
                                    },
                                    [breakpoints[1].minWidth]: {
                                        slidesPerView: NUMBER_OF_SLIDES_TABLET,
                                        centeredSlides: false,
                                        centeredSlidesBounds: false,
                                    },
                                    [breakpoints[2].minWidth]: {
                                        slidesPerView: slidesInViewDesktop,
                                        centeredSlides: false,
                                        centeredSlidesBounds: false,
                                    },
                                }}
                            >
                                {slicedProductList.map(
                                    (item) =>
                                        item.product && (
                                            <SwiperSlide
                                                key={item.product.id}
                                                data-scarabitem={item.product?.key ?? SKIP_RENDER}
                                            >
                                                <Product
                                                    roundedImages={roundedImages}
                                                    product={item.product}
                                                    description={item.product.variants[0].attributes.claim}
                                                    status={item.status}
                                                    backgroundColor={contentBackground?.color}
                                                    backgroundColorHex={contentBackground.backgroundColorHex}
                                                    productRelationType={relationType}
                                                    partnerId={targetPartnerId}
                                                    imageVariant={
                                                        CroppedImageVariant[`ProductSlider${slidesInViewDesktop}`]
                                                    }
                                                />
                                            </SwiperSlide>
                                        )
                                )}
                            </Slider>
                        </div>
                    </div>
                </ContentWrapper>
            </BackgroundImage>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.organism.ProductSlider', ProductSlider);
