import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../store/hooks/hooks';
import { useShopContext } from '../../hooks/useShopContext';
import { useQueryAllowed } from '../../hooks/useQueryAllowed';
import { useProject } from '../../hooks/useProject';
import { determineProductsCategory } from '../../bloomreach/product/determineProductsCategory';
import findCategoryByKey from '../../helper/findCategoryByKey';
import { Category } from '../../api/types/ClientCategory';
import { useLocation } from 'react-router';
import { QueryKeys, queries } from '..';
import { selectedCategoryIdChanged } from '../../store/reducer/category';
import { useCategoryApiClient } from '../../api/hooks/useCategoryApiClient';
import CategoryApiClient from '../../api/CategoryApiClient';
import { useEffect } from 'react';
type AllCategories = QueryKeys['categories']['all'];

const fetchCategories =
    (categoryClient: CategoryApiClient) => (ctx: QueryFunctionContext<AllCategories['queryKey']>) => {
        const [, , locale] = ctx.queryKey;
        return categoryClient.getCategories(locale);
    };

export const useCategories = () => {
    const dispatch = useAppDispatch();
    const apiClient = useCategoryApiClient();
    const { pathname } = useLocation();
    const { categoryTreeRootKey } = useProject();
    const { country, locale } = useShopContext();

    const isAllowedQuery = useQueryAllowed('_catalog_defaults');

    const onSuccess = (categories: Category[]) => {
        const categoryTreeRootId = findCategoryByKey(categories, categoryTreeRootKey)?.id;
        const categoryId = determineProductsCategory(categoryTreeRootId, categories, country, pathname);

        dispatch(selectedCategoryIdChanged({ selectedCategoryId: categoryId }));
    };

    const query = useQuery({
        ...queries.categories.all(locale),
        queryFn: fetchCategories(apiClient),

        enabled: isAllowedQuery,
    });

    const { isPending, isSuccess, data } = query;
    useEffect(() => {
        if (isSuccess && !isPending) {
            onSuccess(data);
        }
    }, [isPending, isSuccess, data]);

    return query;
};
