import { createQueryKeys } from '@lukemorales/query-key-factory';
import { CategoryProductSortOption } from '../../api/types/ProductQueries';

/**
 * Note for getting all categories by the categoryTreeRootKey:
 *
 * We don´t have to include the country in the queryKey,
 * as the countries are sub-trees of the root and we therefore
 * don´t want to refetch in case the country changed (as we already have all the required data).
 */

export const categories = createQueryKeys('categories', {
    all: (locale: string) => ({
        queryKey: [locale],
    }),
    products: (
        categoryId: string,
        country: string,
        currency: string,
        locale: string,
        limit: number,
        offset: number,
        sort?: CategoryProductSortOption
    ) => ({
        queryKey: [categoryId, country, currency, locale, limit, offset, sort],
    }),
});
