/* eslint-disable id-length */
import { useEffect, useState } from 'react';
import { BaseButtonProps } from '../Button/types';
import classNames from 'classnames';
import { Link } from 'react-router';
import Icon from '../Icon';
import LoadingIndicator from '../LoadingIndicator';
import { injectComponent } from '@mediashop/app/component-injector';
import useRoute from '@mediashop/app/hooks/useRoute';

export type LinkButtonProps = BaseButtonProps & {
    target?: string;
    to: string;
};

const componentName = 'link-button';

function LinkButton({
    style = 'primary',
    size = 'medium',
    fullWidth,
    iconLeft,
    iconRight,
    iconSize = 'medium',
    target,
    to,
    loading,
    onClick,
    children,
    className = '',
    customStyles,
    animation = false,
}: LinkButtonProps): JSX.Element {
    const [isButtonAnimated, setIsButtonAnimated] = useState(true);
    const resolvedRoute = useRoute(to);

    useEffect(() => {
        setTimeout(() => {
            setIsButtonAnimated(false);
        }, 7000);
    }, []);

    const targetClassName = classNames(
        className,
        componentName,
        `${componentName}--${style}`,
        `${componentName}--${size}`,
        {
            [`${componentName}--full-width`]: fullWidth,
            [`${componentName}--loading`]: loading,
            [`${componentName}--with-icon-left`]: iconLeft && !loading,
            [`${componentName}--with-icon-right`]: iconRight && !loading,
            [`${componentName}--animate`]: animation && isButtonAnimated,
        }
    );

    const relative = target === '_blank' ? 'noopener' : undefined;

    return (
        <Link
            className={targetClassName}
            onClick={loading ? undefined : onClick}
            style={customStyles}
            to={resolvedRoute ?? to}
            target={target}
            rel={relative}
        >
            {loading ? (
                <LoadingIndicator />
            ) : (
                <>
                    {children}

                    {iconLeft && (
                        <div
                            className={classNames(
                                `${componentName}__icon-left`,
                                `${componentName}__icon`,
                                `${componentName}__icon--${iconSize}`
                            )}
                        >
                            <Icon name={iconLeft} />
                        </div>
                    )}

                    {iconRight && (
                        <div
                            className={classNames(
                                `${componentName}__icon-right`,
                                `${componentName}__icon`,
                                `${componentName}__icon--${iconSize}`
                            )}
                        >
                            <Icon name={iconRight} />
                        </div>
                    )}
                </>
            )}
        </Link>
    );
}

export default injectComponent('pattern.atom.LinkButton', LinkButton);
