import camelCase from 'camelcase';
import { BadgeProps } from '../pattern/atom/Badge';
import { BadgeObject, BadgeTypes, GetBadgesByCategoryParams, ReturnTypeGetBadges } from '../types/badges';
import { Price } from '@mediashop/base/pattern/molecule/Price';
import { Money } from '@mediashop/app/api/types/Money';

const getBadgeObjectByType = (type: BadgeTypes, text?: string, textElement?: React.ReactNode) => {
    const categoryProps = {
        1: { cornerPosition: 'bottom-left' },
        2: { cornerPosition: 'bottom-right' },
        3: {
            cornerPosition: 'bottom-left',
            size: 'small',
        },
    } as const;

    const getBadgeObject = (
        category: BadgeObject['category'],
        priority: number,
        overrideProps: Partial<BadgeProps> = {}
    ): BadgeObject => ({
        category,
        priority,
        type,
        badgeProps: { ...categoryProps[category], ...overrideProps, text, textElement },
    });

    switch (type) {
        case 'TOPSELLER':
            // CT-Label: grau_CUSTOM
            return getBadgeObject(1, 5, { style: 'topseller' });
        case 'GRATISZUBEHOER':
            // CT-Label: GRATIS_ZUBEHOER
            return getBadgeObject(2, 3, { style: 'gratis' });
        case 'NEU':
            // CT-Label: gelb_B-Ware
            return getBadgeObject(1, 6, { style: 'bware' });
        case 'PROPERTY':
            // CT-Label: turkis_NEU
            return getBadgeObject(1, 4, { style: 'property' });
        case 'SPARSET':
            // CT-Label: rot_SALE
            return getBadgeObject(1, 2, { style: 'sparset' });
        case 'AKTION':
            return getBadgeObject(1, 1, { style: 'aktion' });
        case 'RATENZAHLUNG':
            return getBadgeObject(3, 1, { style: 'raten' });
        case 'RATENZAHLUNGDEAL':
            return getBadgeObject(3, 1, { style: 'ratensavings' });
        case 'SAVINGS':
            return getBadgeObject(3, 3, { style: 'savings' });
        case 'SAVINGSPDP':
            return getBadgeObject(3, 3, { style: 'savings-pdp' });
        case 'XPLUSYGRATIS':
            return getBadgeObject(3, 2, { style: 'xplusy' });
        case 'XPLUSYGRATISTOP':
            return getBadgeObject(1, 3, { style: 'xplusy' });
        default: {
            // this ensures that all the types are handled, red squiggly line if not
            const exhaustiveCheck: never = type;
            throw new Error(`Unknown badge type: ${exhaustiveCheck}`);
        }
    }
};

export const getSavingsPercentage = (originalPrice: number, currentPrice: number): number =>
    Math.floor(((originalPrice - currentPrice) / originalPrice) * 100);

// ensures text is always 1 + 1 instead of 1+1 or other variations
const ensurePlusFormat = (text: string) => (text.includes('+') ? text.split(' ').join('').split('+').join('+') : text);

// eslint-disable-next-line complexity
export const getBadgesByCategory = ({
    installmentPrice,
    intl,
    isPDP,
    locale,
    product,
    storeKey,
    variant,
    variantCustom,
}: GetBadgesByCategoryParams): ReturnTypeGetBadges => {
    let badges: BadgeObject[] = [];

    const badgesMapped: Record<string, any>[] = [];
    for (const badgeAttributes of variant.attributes.badges ?? []) {
        const badgeObj = {} as Record<string, any>;
        for (const badgeAttribute of badgeAttributes) {
            badgeObj[camelCase(badgeAttribute.name)] = badgeAttribute.value;
        }
        badgesMapped.push(badgeObj);
    }

    for (const badgeObj of badgesMapped) {
        const { type, text, storeKey: badgeStoreKey } = badgeObj;

        const textValue = text?.[locale];

        if (storeKey !== badgeStoreKey) {
            continue;
        }

        if (type && textValue) {
            badges.push(getBadgeObjectByType(type.key, textValue));
        }
    }

    let originalPrice: Money | undefined;
    let price: Money | undefined;

    if (variantCustom?.price && variantCustom?.originalPrice) {
        originalPrice = variantCustom.originalPrice;
        price = variantCustom.price;
    } else {
        originalPrice = variant.originalPrice;
        price = variant.price;
    }

    const savingsPercentage = getSavingsPercentage(originalPrice?.centAmount ?? 0, price?.centAmount) ?? 0;

    if (originalPrice && price?.priceType) {
        if (savingsPercentage > 0 && ['DEAL', 'UPSELL'].includes(price.priceType)) {
            const savingsPercentageMessage = intl.formatMessage(
                { id: 'product.badges.deal' },
                { count: savingsPercentage }
            );
            badges.push(getBadgeObjectByType('AKTION', savingsPercentageMessage));
            if (savingsPercentage >= 1) {
                const savingsMessage = intl.formatMessage(
                    { id: isPDP ? 'product.badges.savingsPDP' : 'product.badges.savings' },
                    { count: savingsPercentage }
                );
                badges.push(getBadgeObjectByType(isPDP ? 'SAVINGSPDP' : 'SAVINGS', savingsMessage));
            }
        }
    }

    // In case the variant has a WKO price we may not show a SPARSET badge
    if (price?.centAmount === 0 && badges.findIndex((badge) => badge.type === 'SPARSET') >= 0) {
        badges = badges.filter((badge) => badge.type !== 'SPARSET');
    }

    const { setTypeLclzd } = variant.attributes;

    if (setTypeLclzd) {
        const gratisMessage = intl.formatMessage(
            {
                id: 'product.badges.xplusygratis',
            },
            { text: ensurePlusFormat(setTypeLclzd) }
        );
        badges.push(getBadgeObjectByType('XPLUSYGRATIS', gratisMessage));
        badges.push(getBadgeObjectByType('XPLUSYGRATISTOP', gratisMessage));
    }

    const installment = variantCustom?.installments ?? price?.installments;

    const hasInstallmentVariants = product
        ? product.variants.length > 1 && variant.existsOtherThenCheapestPrices
        : undefined;

    if (installment && installmentPrice) {
        const installmentMessage = intl.formatMessage(
            { id: hasInstallmentVariants ? 'product.badges.installmentsVariants' : 'product.badges.installments' },
            { count: installment, price: <Price price={installmentPrice} /> }
        );
        if (price?.priceType === 'DEAL') {
            badges.push(getBadgeObjectByType('RATENZAHLUNGDEAL', undefined, installmentMessage));
        } else {
            badges.push(getBadgeObjectByType('RATENZAHLUNG', undefined, installmentMessage));
        }
    }

    const sortedBadges = badges.sort((first, second) => first.priority - second.priority);
    const hasAktionBadge = badges.filter((badge) => badge.type === 'AKTION').length > 0;

    // we display only two top badges unless the two are AKTION and SALE, in this case we only display AKTION
    const topBadges = sortedBadges
        .filter((badge) => {
            const isSaleOrSparsetWithAktion = badge.type === 'SPARSET' && hasAktionBadge;
            const isSparsetWithUvpAndNoOriginalPrice =
                badge.type === 'SPARSET' &&
                badge.badgeProps.text === 'UVP' &&
                (!originalPrice || originalPrice.centAmount <= price.centAmount);

            if (isSaleOrSparsetWithAktion || isSparsetWithUvpAndNoOriginalPrice) {
                return false;
            }
            return true;
        })
        .filter((badge) => badge.category === 1)
        .slice(0, 2);

    const saleBadgeIndex = topBadges.findIndex((badge) => badge.type === 'SPARSET');

    /**
     * Products with SPARSET-Badge should automatically get a -x% xtension.
     */
    if (saleBadgeIndex >= 0 && savingsPercentage > 0 && !hasAktionBadge) {
        topBadges[saleBadgeIndex].badgeProps.text =
            `${topBadges[saleBadgeIndex].badgeProps.text} -${savingsPercentage}%`;
    }

    const bottomBadges = sortedBadges.filter((badge) => badge.category === 2);
    const hasSaleBadge = topBadges.filter((badge) => badge.type === 'SPARSET').length > 0;

    let detailBadges = sortedBadges.filter((badge) => badge.category === 3);
    if (detailBadges?.length > 0) {
        detailBadges = [detailBadges[0]];
    }

    return { topBadges, bottomBadges, detailBadges, hasSaleBadge };
};
