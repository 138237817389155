import { useLocation } from 'react-router';
import { SlugProductEntity } from '../store/reducer/product';
import { getProductSlug } from '../helper/getProductSlug';
import { useProduct } from './useProduct';

type UseGetProductsBySlugReturnType = {
    product?: SlugProductEntity;
    status?: SlugProductEntity['status'];
};

/**
 * Returns a product with the provided slug or default slug
 * @return ProductEntity
 */
export const useGetProductBySlug = (): UseGetProductsBySlugReturnType => {
    const { slugProduct } = useProduct();
    const currentLocation = useLocation();

    const slug = getProductSlug(currentLocation.pathname);

    const isProductForSlug = slugProduct.slug === slug || slugProduct.product?.subscriptionProduct?.slug === slug;
    return {
        status: isProductForSlug ? slugProduct?.status : undefined,
        product: isProductForSlug ? slugProduct : undefined,
    };
};
