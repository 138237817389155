import { Nosto404, NostoCheckout, NostoHome, NostoOther } from '@nosto/nosto-react';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { SKIP_RENDER } from '../constants/semanticConstants';
import { useProject } from '../hooks/useProject';
import NostoCategoryWrapper from './NostoCategoryWrapper';
import NostoProductWrapper from './NostoProductWrapper';
import NostoSearchWrapper from './NostoSearchWrapper';

const NostoTrackingConnector = (): ReactElement | null => {
    const { searchProvider } = useProject();
    const { pathname } = useLocation();
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (searchProvider !== 'Nosto' || !mounted) {
        return SKIP_RENDER;
    }

    const isFrontPage = pathname === '/';
    const isSearchPage = pathname.includes('/search-results');
    const isCategoryPage = pathname.includes('/c/');
    const isProductPage = pathname.includes('/p/');
    const isCheckoutPage = pathname.includes('/checkout');
    const isCheckoutSuccessPage = pathname.includes('/checkoutsuccess');
    const isCartPage = pathname.includes('/cart');
    const isErrorPage = pathname.includes('/not-found');

    if (isFrontPage) {
        return <NostoHome />;
    } else if (isSearchPage) {
        return <NostoSearchWrapper />;
    } else if (isCategoryPage) {
        return <NostoCategoryWrapper />;
    } else if (isProductPage) {
        return <NostoProductWrapper />;
    } else if ((isCheckoutPage || isCartPage) && !isCheckoutSuccessPage) {
        return <NostoCheckout />;
    } else if (isErrorPage) {
        return <Nosto404 />;
    }
    return <NostoOther />;
};

export default NostoTrackingConnector;
