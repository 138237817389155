import * as React from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import useIsPreview from '@mediashop/app/hooks/useIsPreview';
import useRoute from '@mediashop/app/hooks/useRoute';
import { Navigate } from 'react-router';
import { BaseProps } from '@mediashop/app/bloomreach/types';

type SoftRedirectProps = BaseProps & {
    children?: React.ReactNode;
    reason: string;
    to?: string;
};

/* eslint-disable id-length, no-console */
/**
 * A redirect which doesn't work when we're in the bloomreach preview.
 * Provide a reason for easier debugging.
 */
function SoftRedirect({ to = '/', reason, children }: SoftRedirectProps) {
    const isPreview = useIsPreview();
    const target = useRoute(to) ?? to;

    if (isPreview) {
        return (
            <div className="alert">
                {children ?? 'Bloomreach Preview detected. Client redirect prevented.'}
                {reason ? (
                    <>
                        <br />
                        {reason}
                    </>
                ) : null}
            </div>
        );
    }

    // Print debugging info
    if (process.env.NODE_ENV === 'development') {
        console.log(`SoftRedirect: An automatic redirect was triggered from a component. Reason: ${reason}`);
    }

    return <Navigate to={target} />;
}

export default injectComponent('pattern.atom.SoftRedirect', SoftRedirect);
