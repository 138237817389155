import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Product as ProductSchema } from 'schema-dts';
import { BaseProps } from '@mediashop/app/bloomreach/types';
import { DetailedProduct } from '@mediashop/app/api/types/ClientProductDetailed';
import { VariantAsset } from '@mediashop/app/api/types/ClientProduct';
import { generateProductSchemaForAllVariants } from './schema-generators';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useIntl } from 'react-intl';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';
import { useHrefLang } from '@mediashop/app/hooks/useHrefLang';

/**
 * Trim description to provided number of chars
 * @param descriptionLength expected length
 * @param product product object
 * @returns trimmed meta text
 */
const trimDescriptionForMeta = (descriptionLengthLimit: number, product?: DetailedProduct) => {
    let description = product?.metaDescription;
    const removeAfterLastSpaceRegEx = /\s\S*$/;
    if (description?.length && description.length > descriptionLengthLimit) {
        description = description.slice(0, descriptionLengthLimit - 1).replace(removeAfterLastSpaceRegEx, ' ...');
    }
    return description;
};

type Props = BaseProps & {
    mainImage?: VariantAsset;
    product: DetailedProduct;
};

export function ProductDetailsSeo({ mainImage, product }: Props): JSX.Element {
    const { seoMetaTitle, showReviews } = useChannelSettings();
    const {
        location: { href, hostname },
    } = useShopContext();

    const { formatMessage } = useIntl();

    const hrefLang = useHrefLang();

    const store = seoMetaTitle ?? hostname;
    const intlStore = store === EMPTY_STRING ? store.replace(EMPTY_STRING, 'EMPTY') : store;

    const pageTitle = formatMessage(
        { id: 'product.pageTitle' },
        {
            title: product.metaTitle,
            store: intlStore,
        }
    );

    const productSchema = generateProductSchemaForAllVariants(product, href, showReviews);

    const hrefLangUrlMap = product.alternativeShops.reduce((map, { locale, url }) => {
        return { ...map, [locale.toLowerCase()]: url };
    }, hrefLang);

    return (
        <Helmet
            script={
                /** Schema tags */
                productSchema ? [helmetJsonLdProp<ProductSchema>(productSchema)] : undefined
            }
        >
            {/* Page title */}
            <title>{pageTitle}</title>

            {/* Meta description */}
            <meta name="description" content={trimDescriptionForMeta(150, product)} />

            {/* Meta thumbnail */}
            {mainImage ? <meta name="thumbnail" content={mainImage.sources[0].uri} /> : SKIP_RENDER}

            {/* Href-lang */}
            {Object.entries(hrefLangUrlMap).map(([locale, pathName]) => (
                <link key={locale} rel="alternate" hrefLang={locale} href={pathName} />
            ))}

            {/** Open Graph tags */}
            {mainImage ? <meta property="og:image" content={mainImage.sources[0].uri} /> : SKIP_RENDER}
            {product ? <meta property="og:image:alt" content={product.metaTitle} /> : SKIP_RENDER}
        </Helmet>
    );
}
