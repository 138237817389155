import { type MouseEventHandler, type CSSProperties } from 'react';
import { useProject } from '../hooks/useProject';
import useRewriteLinks from '../hooks/useRewriteLinks';
import LinkHelper from '../helper/LinkHelper';
import { useLocation } from 'react-router';
import BaseUrlHelper from '../helper/BaseUrlHelper';
import parseHtml from 'html-react-parser';
import { BaseProps } from '../bloomreach/types';
import { useNavigate } from '../hooks/useNavigate';

export type BrxHtml =
    | string
    | {
          value?: string;
      };

export type Props = BaseProps & {
    html?: BrxHtml;
    onClick?: MouseEventHandler;
    style?: CSSProperties;
};

function isModifiedEvent(event) {
    return Boolean(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

/**
 * Render html coming from bloomreach.
 * Correctly rewrites urls.
 */
export default function BloomreachHtml({ html, style, ...props }: Props): JSX.Element | null {
    const rewritten = useRewriteLinks(typeof html === 'object' ? html?.value : html);
    const navigate = useNavigate();
    const location = useLocation();
    const { basePath } = useProject();

    /**
     * Handle click events in case an internal link was clicked.
     * Then we can use the react-router to prevent a full-page reload.
     */
    const handleClick = (event) => {
        const { target } = event;
        if (typeof props.onClick === 'function') {
            props.onClick(event);
        }

        // Push to history only for internal links
        if (
            event.defaultPrevented ||
            target.tagName !== 'A' ||
            (target.target && target.target !== '_self') ||
            isModifiedEvent(event) ||
            LinkHelper.isExternalUrl(target.href) ||
            LinkHelper.isAnchorLink(target.href, location.pathname)
        ) {
            return;
        }

        event.preventDefault();

        const targetPath = BaseUrlHelper.removeBasePathFromPath(LinkHelper.getRelativeUrl(target.href), basePath);
        navigate(targetPath);
    };

    if (!rewritten) {
        return null;
    }

    return (
        <div {...props} style={style} onClick={handleClick}>
            {parseHtml(rewritten)}
        </div>
    );
}
