import { BrComponent, BrPage } from '@bloomreach/react-sdk';
import { Configuration as BrConfiguration } from '@bloomreach/spa-sdk';
import NostoSessionConnector from '../../nosto/NostoSessionConnector';
import NostoTrackingConnector from '../../nosto/NostoTrackingConnector';
import { RouteInterceptor } from '../../routing/RouteInterceptor';
import PageMetadata from '../../page-metadata';
import CategoryDataConnector from '../connector/CategoryDataConnector';
import CustomerSignInStateConnector from '../connector/CustomerSignInStateConnector';
import ProductDataConnector from '../connector/ProductDataConnector';
import CartDataConnector from '../connector/CartDataConnector';
import OrderCreationConnector from '../connector/OrderCreationConnector';
import PageDataConnector from '../connector/PageDataConnector';
import Notification from '../../components/Notification';
import { BrxMapping, BrxPageModel } from '../types';
import CartPartnerConnector from '../connector/CartPartnerConnector';
import useIsClientSide from '../../hooks/useIsClientSide';
import { SKIP_RENDER } from '../../constants/semanticConstants';
import CategorySEO from '@mediashop/catalog-base/pattern/atom/CategorySEO';
import { ScrollRestoration } from 'react-router';

type Props = {
    config: BrConfiguration;
    mapping: BrxMapping;
    page?: BrxPageModel;
};

export const CatalogBloomreachApp = ({ config, mapping, page }: Props): JSX.Element => {
    const isClientSide = useIsClientSide();

    return (
        <BrPage configuration={config} mapping={mapping} page={page}>
            {isClientSide && <RouteInterceptor />}
            <PageMetadata />
            <CategoryDataConnector />
            <ProductDataConnector />
            <CartDataConnector />
            <CartPartnerConnector />
            <CustomerSignInStateConnector />
            {isClientSide ? <OrderCreationConnector /> : SKIP_RENDER}
            <PageDataConnector />
            <Notification />
            <CategorySEO />
            <NostoSessionConnector />

            <BrComponent path="top" />
            <BrComponent path="header" />
            <BrComponent path="nav" />
            <BrComponent path="content" />
            <BrComponent path="account" />
            <BrComponent path="main" />
            <BrComponent path="footer" />

            <NostoTrackingConnector />
            {isClientSide && <ScrollRestoration />}
        </BrPage>
    );
};
