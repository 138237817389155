import { useCategory } from '@mediashop/app/hooks/useCategory';
import useGetCategoryPath from '@mediashop/app/hooks/useGetCategoryPath';
import useNostoSearchAnalytics from '@mediashop/app/hooks/useNostoSearchAnalytics';
import { useProduct } from '@mediashop/app/hooks/useProduct';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { findCategoryIdBySlug } from '@mediashop/app/bloomreach/product/determineProductsCategory';
import { PRODUCT_GRID_PAGE_LIMIT } from '@mediashop/app/constants/api';
import findCategoryById from '@mediashop/app/helper/findCategoryById';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import { useCategoryProducts } from '@mediashop/app/queries/categories/useCategoryProducts';
import { useProductListAnalytics } from '@mediashop/app/analytics/hooks/useProductListAnalytics';
import Pagination from '../../Pagination';
import { BaseProps, BrxHeadlines, BrxRelationType, ContentBackgroundProps } from '@mediashop/app/bloomreach/types';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Grid from '../Grid';
import { Product } from '@mediashop/app/api/types/ClientProduct';
import { useProductClickedAnalytics } from '@mediashop/app/analytics/hooks/useProductClickedAnalytics';
import { AboveTheFoldText } from './AboveTheFoldText';
import { BelowTheFoldText } from './BelowTheFoldText';
import FullScreenLoadingIndicator from '@mediashop/base/pattern/atom/FullScreenLoadingIndicator';
import { useCategories } from '@mediashop/app/queries/categories/useCategories';
import { Category } from '@mediashop/app/api/types/ClientCategory';
import { SpecialDealProps } from '..';
import { filterDoubleSpecialDealProducts, getFormattedPageLink, getSpecialDealProduct } from '../functions';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';
import SelectBox from '@mediashop/base/pattern/atom/SelectBox/SelectBox';
import { CategoryProductSortingOption, CategoryProductSortOption } from '@mediashop/app/api/types/ProductQueries';

const URL_PARAM_PAGE = 'page';
const URL_PARAM_SUBCATEGORY = 'subcategory';

const getParentCategory = (categories: Category[], subcategorySlug: string) => {
    if (subcategorySlug) {
        const categoryId = findCategoryIdBySlug(categories, subcategorySlug);
        const subcategory = findCategoryById(categories, categoryId);
        if (subcategory) {
            return findCategoryById(categories, subcategory.parentId);
        }
    }
    return undefined;
};

const componentName = 'product-grid-category';

type ProductGridCategoryListProps = BaseProps & {
    headlineFontColor?: string;
    headlines?: BrxHeadlines;
    showHeadline: boolean;
    productRelationType: BrxRelationType;
    limit?: number;
    specialDealData?: SpecialDealProps;
    contentBackground?: ContentBackgroundProps;
};

/**
 * Displays the Product Grid
 *
 * @constructor
 */
// eslint-disable-next-line max-lines-per-function
export function ProductGridCategoryList({
    headlineFontColor,
    headlines,
    showHeadline,
    productRelationType,
    limit = PRODUCT_GRID_PAGE_LIMIT,
    specialDealData,
    contentBackground,
}: ProductGridCategoryListProps): JSX.Element {
    const intl = useIntl();

    const { data: categories = [] } = useCategories();
    const { selectedCategoryId } = useCategory();

    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const { products: storeProducts } = useProduct();
    const specialDealProduct = getSpecialDealProduct(storeProducts, specialDealData);

    const categoryPath = useGetCategoryPath();
    const { trackSearch, trackProductClick } = useNostoSearchAnalytics();

    /**
     * ---
     * Get page parameter from URL. For page 1 the query-parameter is not set.
     */
    const page = useMemo(() => {
        const pageString = new URLSearchParams(search).get(URL_PARAM_PAGE) ?? '1';
        return parseInt(pageString, 10);
    }, [search]);

    /**
     * ---
     * Get subcategory from URL (query-parameter);
     */
    const selectedSubcategorySlug = useMemo(
        () => new URLSearchParams(search).get(URL_PARAM_SUBCATEGORY) ?? '',
        [search]
    );

    const [selectedSorting, setSelectedSorting] = useState<CategoryProductSortOption>(
        CategoryProductSortingOption.Bestseller
    );

    /**
     * ---
     * Load products for the selected category.
     */
    const {
        isFetching,
        isLoading,
        data: { products, total } = {
            products: [],
            total: 0,
        },
    } = useCategoryProducts({
        categoryId: selectedCategoryId,
        page,
        limit,
        withSpecialDeal: Boolean(specialDealProduct),
        sort: selectedSorting,
    });

    const sortOptions = Object.values(CategoryProductSortingOption).map((option) => ({
        value: option,
        label: intl.formatMessage({ id: `productListing.sort.${option}` }),
        iconName: EMPTY_STRING,
    }));

    const totalNumberPages = Math.ceil(total / limit);

    useEffect(() => {
        if (!isFetching && categoryPath) {
            const isKeyword = new URLSearchParams(search).has('keyword');
            trackSearch({
                type: isKeyword ? 'autocomplete' : 'category',
                categoryPath,
                products,
                totalProductCount: total,
                limit,
                offset: (page - 1) * limit,
                isKeyword,
            });
        }
    }, [products, isFetching, categoryPath]);

    /**
     * ---
     * Get currently active category.
     */
    const category = useMemo(() => findCategoryById(categories, selectedCategoryId), [categories, selectedCategoryId]);

    const parentCategory = useMemo(
        () => getParentCategory(categories, selectedSubcategorySlug) ?? category,
        [categories, selectedSubcategorySlug, category]
    );

    /**
     * ---
     * Syncs the query params in the URL.
     */
    const syncParamsWithURL = useCallback(
        (params: Record<string, string | undefined>) => {
            const urlParams = new URLSearchParams();
            Object.entries(params).forEach(([key, value]) => value && urlParams.set(key, value));

            navigate({ pathname, search: urlParams.toString() });
        },
        [navigate, pathname]
    );

    /**
     * Analytics (GTM)
     */
    useProductListAnalytics(products, 'Category_Main');
    const dispatchProductListItemClicked = useProductClickedAnalytics();

    const transitionToPage = (targetPage: number) => {
        syncParamsWithURL({
            [URL_PARAM_PAGE]: targetPage > 1 ? targetPage.toString() : undefined,
            [URL_PARAM_SUBCATEGORY]: selectedSubcategorySlug,
        });

        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 200);
    };

    const handleSortChange = (newSort) => {
        setSelectedSorting(newSort.value);
    };

    const handleProductClick = (product: Product, position: number) => {
        dispatchProductListItemClicked({
            listName: parentCategory?.name ?? EMPTY_STRING,
            position,
            product,
        });

        trackProductClick('category', product);
    };

    const getCategoryHeadlines = (): Omit<BrxHeadlines, 'contentType' | 'displayName' | 'name'> => {
        return {
            ...headlines,
            headline: parentCategory?.name ?? '',
            headlineStyle: 'h1',
            textStyle: 'normalCase',
            subheadline: parentCategory?.description,
        };
    };

    const categoryHeadlines: Omit<BrxHeadlines, 'contentType' | 'displayName' | 'name'> = getCategoryHeadlines();

    const headlinesWithFallback = showHeadline ? categoryHeadlines : headlines;

    return (
        <div className={`${componentName}__wrapper`}>
            {category && (
                <>
                    {headlinesWithFallback ? (
                        <Headlines
                            className={`${componentName}__headlines`}
                            textColor={headlineFontColor}
                            {...headlinesWithFallback}
                        />
                    ) : (
                        SKIP_RENDER
                    )}
                </>
            )}

            {/** Above the fold text */}
            <AboveTheFoldText
                category={category}
                className={classNames(`${componentName}__above-the-fold`, {
                    [`${componentName}__above-the-fold--with-title`]: showHeadline,
                })}
            />

            {/** Header row with filter for subcategory and product count */}
            <div className={`${componentName}__filter-header`}>
                {
                    /** If the category has no products show text*/
                    !isLoading && !isFetching && !products.length && Boolean(selectedCategoryId) ? (
                        <div className={`${componentName}__no-products`}>
                            <FormattedMessage id="productListing.noProducts" />
                        </div>
                    ) : (
                        <h2 className={`${componentName}__product-amount`}>
                            <FormattedMessage
                                id="productListing.products"
                                values={{ count: specialDealProduct ? total + 1 : total }}
                            />
                        </h2>
                    )
                }
                {sortOptions.length > 0 && (
                    <SelectBox
                        className={`${componentName}-sort`}
                        defaultValue={sortOptions[0]}
                        onChange={handleSortChange}
                        options={sortOptions}
                    />
                )}
            </div>

            {/** Grid */}
            <Grid
                products={specialDealProduct ? filterDoubleSpecialDealProducts(products, specialDealProduct) : products}
                specialDealProduct={page === 1 ? specialDealProduct : undefined}
                specialDealData={page === 1 ? specialDealData : undefined}
                productRelationType={productRelationType}
                onProductClick={handleProductClick}
                contentBackground={contentBackground}
            />

            {/** Pagination */}
            {category && totalNumberPages > 1 && (
                <div className={`${componentName}__pagination-container`}>
                    <Pagination
                        className={`${componentName}__pagination`}
                        pageCount={totalNumberPages}
                        selectedPage={page}
                        onPageChange={transitionToPage}
                        hrefBuilder={() => getFormattedPageLink(page, search, pathname, URL_PARAM_PAGE)}
                    />
                </div>
            )}

            {/** Below the fold text */}
            <BelowTheFoldText category={category} className={`${componentName}__below-the-fold`} />

            {/** Loading indicator */}
            {isFetching ? <FullScreenLoadingIndicator /> : SKIP_RENDER}
        </div>
    );
}
