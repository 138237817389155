import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { noConsentPages } from '@mediashop/app/constants/noCookieConsentPages';
import { useNonce } from '../hooks/useNonce';

interface Props {
    usercentricsSettingsID: string;
}

/**
 * Render usercentrics script tags
 * @param usercentricsSettingsID
 */
export default function Usercentrics({ usercentricsSettingsID }: Props): JSX.Element | null {
    const location = useLocation();
    const hideCookieConsent = noConsentPages.includes(location.pathname.split('/').pop() as string);
    const { nonce } = useNonce();

    return !hideCookieConsent ? (
        <Helmet>
            <link rel="preconnect" href="//app.usercentrics.eu"></link>
            <link rel="preconnect" href="//api.usercentrics.eu"></link>
            <link
                nonce={nonce}
                rel="preload"
                href="//app.usercentrics.eu/browser-ui/latest/loader.js"
                as="script"
            ></link>

            <script
                nonce={nonce}
                id="usercentrics-cmp"
                data-settings-id={usercentricsSettingsID}
                src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
                async
            ></script>

            <script
                nonce={nonce}
                type="application/javascript"
                src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
            ></script>
        </Helmet>
    ) : (
        SKIP_RENDER
    );
}
