import { useProject } from '../hooks/useProject';
import { useAppSelector } from '../store/hooks/hooks';
import { BrxMapping, BrxPageModel } from './types';
import { CatalogBloomreachApp } from './BloomreachApp/CatalogBloomreachApp';
import { SplpBloomreachApp } from './BloomreachApp/SplpBloomreachApp';
import brxHttpClient from './httpClient';
import { useLocation } from 'react-router';

/**
 * A factory, providing the necessary bloomreach-communication components
 */
export const appFactory = (brEndpoint: string, brMapping: BrxMapping, brPage?: BrxPageModel): (() => JSX.Element) => {
    /**
     * Bloomreach App, depending on platform
     */
    function BloomreachApp() {
        const { platform, takeLanguageFromUrl } = useProject();
        const { pathname, search } = useLocation();

        // Don´t use useShopContext here, as this would result in too many re-renders
        const country = useAppSelector((state) => state.context.country);
        const isBrX = useAppSelector((state) => state.context.isBrX);
        const locale = useAppSelector((state) => state.context.locale);

        const httpClient = brxHttpClient({ country, isBrX, locale }, takeLanguageFromUrl);

        const config = {
            httpClient,
            endpoint: brEndpoint,
            endpointQueryParameter: 'brxm',
            request: {
                // Location.pathname contains the actual path with the basePath already removed by react-router.
                path: `${pathname}${search}`,
            },
        };

        switch (platform) {
            case 'Catalog':
                return <CatalogBloomreachApp config={config} mapping={brMapping} page={brPage} />;
            case 'SPLP':
            default:
                return <SplpBloomreachApp config={config} mapping={brMapping} page={brPage} />;
        }
    }

    return BloomreachApp;
};
