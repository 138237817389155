import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import { DehydratedState, HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Routes,
    StaticRouter,
    Navigate,
} from 'react-router';
import { I18nProvider } from './i18n';
import NostoProviderWrapper from './nosto/NostoProviderWrapper';
import RouterErrorBoundary from './RouterErrorBoundary';
import { RootStore } from './store';
import Loader, { LoaderContext } from './store/loader';
import { appFactory } from './bloomreach/appFactory';
import { FilledContext as HelmetContext, HelmetProvider } from 'react-helmet-async';
import { MergedTheme, ThemeContext } from './Theme';
import { ExchangeRatesSubset } from './types/exchangeRates';
import { DetailedProduct } from './api/types/ClientProductDetailed';
import { BrxPageModel } from './bloomreach/types';
import { PaymentProvider } from './payment/PaymentProvider';
import { getEndpointWithBasePath } from './api/ApiClient';
import { ProjectStateFinished } from './store/reducer/project';
import { QueryProductsResponse } from './api/types/ProductQueries';
import { ScrollLayout } from './routing/ScrollLayout';

export interface AppConfig {
    bloomreachApiEndpoint: string;
    key: string;
    locales: string[];
    product: string;
    theme: string;
}

export interface CommercetoolsData {
    products: QueryProductsResponse;
    slugProduct?: DetailedProduct;
}

/**
 * Props which may be provided by the SSR-app.
 * (can be found in the data-props attribute of the #app element)
 */
export interface AppPropsForSSR {
    locale: string;
    isLocaleFromCookie: boolean;
    isBrX: boolean;
    currency: string;
    country: string;
    config: any;
    geoIPCountryCode: string;
    route: string;
    userAgent?: string;
    hasUADesktopHeader: boolean;

    location: {
        origin: string;
        href: string;
        hostname: string;
    };

    exchangeRates?: ExchangeRatesSubset;

    selectedProductVariantSku?: string;
}

export interface AppProps {
    /**
     * You may provide your own client instead of the default http-client connecting
     * to the configured bloomreach-instance.
     */
    helmetContext: HelmetContext;
    isSSR?: boolean;
    location?: string;
    theme: MergedTheme;
    store: RootStore;
    queryClient?: QueryClient;
    dehydratedState?: DehydratedState;
    pageModel?: BrxPageModel;
}

const defaultQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60,
        },
    },
});

export default class App extends React.Component<AppProps> {
    BrApp: () => JSX.Element;
    loader: Loader;
    helmetContext: HelmetContext;
    queryClient: QueryClient;
    dehydratedState: DehydratedState;
    basePath: string;

    constructor(props: AppProps) {
        super(props);

        const { basePath, bloomreachApiEndpoint, key } = props.store.getState().project as ProjectStateFinished;

        this.basePath = basePath;

        this.helmetContext = props.helmetContext ?? ({} as HelmetContext);

        // Initialize all loaders.
        this.loader = new Loader(props.store, key, getEndpointWithBasePath(basePath));

        this.BrApp = appFactory(bloomreachApiEndpoint, this.props.theme.brComponents, props.pageModel);

        this.queryClient = this.props.queryClient ?? defaultQueryClient;
        this.dehydratedState = this.props.dehydratedState ?? globalThis.__REACT_QUERY_STATE__;
    }

    render(): React.ReactNode {
        const {
            props: { pageModel, store, theme },
        } = this;

        const useInformalGerman = Boolean(pageModel?.channel?.info?.props?.useInformalGerman);

        const renderRouter = () => {
            if (this.props.isSSR) {
                return (
                    <StaticRouter basename={this.basePath} location={this.props.location!}>
                        <Routes>
                            <Route element={<ScrollLayout />} errorElement={<RouterErrorBoundary />}>
                                <Route path="/*" element={<this.BrApp />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Route>
                        </Routes>
                    </StaticRouter>
                );
            }

            const router = createBrowserRouter(
                createRoutesFromElements(
                    <Route element={<ScrollLayout />} errorElement={<RouterErrorBoundary />}>
                        <Route path="/*" element={<this.BrApp />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                ),
                {
                    basename: this.basePath,
                }
            );

            return <RouterProvider router={router} />;
        };

        return (
            <ErrorBoundary>
                <QueryClientProvider client={this.queryClient}>
                    <HydrationBoundary state={this.dehydratedState}>
                        <ReduxProvider store={store}>
                            <ThemeContext.Provider value={theme}>
                                <LoaderContext.Provider value={this.loader}>
                                    <I18nProvider useInformalGerman={useInformalGerman}>
                                        <PaymentProvider>
                                            <NostoProviderWrapper>
                                                <HelmetProvider context={this.helmetContext}>
                                                    {renderRouter()}
                                                </HelmetProvider>
                                            </NostoProviderWrapper>
                                        </PaymentProvider>
                                    </I18nProvider>
                                </LoaderContext.Provider>
                            </ThemeContext.Provider>
                        </ReduxProvider>
                    </HydrationBoundary>
                </QueryClientProvider>
            </ErrorBoundary>
        );
    }
}
