import { BaseProps } from '@mediashop/app/bloomreach/types';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';
import { BrXLinkOpenInTabWithColorsAndBorder } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { MainNavigationCustomNavigationProps, MainNavigationCustomQuickLinksProps } from '../../types';
import BurgerMenuButton from '../BurgerMenuButton';
import BurgerMenu from '../index';

const componentName = 'burger-menu-wrapper';

type BurgerMenuButtonProps = BaseProps & {
    specialButton?: BrXLinkOpenInTabWithColorsAndBorder;
    navigation: MainNavigationCustomNavigationProps;
    quickLinks: MainNavigationCustomQuickLinksProps;
};

const BurgerMenuWrapper = ({ navigation, specialButton, quickLinks }: BurgerMenuButtonProps) => {
    const { pathname } = useLocation();
    const deviceType = useDeviceType();
    const isMobileOrTablet = deviceType === 'mobile' || deviceType === 'tablet';

    const ref = useRef<HTMLDivElement | null>(null);
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

    useOutsideClicked(ref, () => setIsBurgerMenuOpen(false));

    useEffect(() => {
        setIsBurgerMenuOpen(false);
    }, [pathname, isMobileOrTablet]);

    return (
        <div ref={ref} className={componentName}>
            <BurgerMenuButton
                onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
                isBurgerMenuOpen={isBurgerMenuOpen}
            />
            <BurgerMenu
                closeBurgerMenu={() => setIsBurgerMenuOpen(false)}
                navigation={navigation}
                isOpen={isBurgerMenuOpen}
                specialButton={specialButton}
                quickLinks={quickLinks}
            />
        </div>
    );
};

export default BurgerMenuWrapper;
