import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import { useProduct } from '../../hooks/useProduct';
import { useAppDispatch } from '../../store/hooks/hooks';
import { useLoader } from '../../store/loader';
import { NeededPriceType } from '../../api/types/PriceType';
import { BrxRelationType } from '@mediashop/app/bloomreach/types';
import isPlainObject from 'lodash/isPlainObject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { slugProductRemoved } from '@mediashop/app/store/reducer/product';
import { matchesSkuRegex } from '@mediashop/app/helper/skuRegexMatch';
import { useProductsBySku } from '../../queries/products/useProductsBySku';
import { useBrxPageContext } from '../useBrxPageContext';
import { BrxUtils } from '../utils/BrxUtils';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { useNavigate } from '../../hooks/useNavigate';

const mapBrxRelationTypeToNeededPriceType = (relationType?: BrxRelationType) => {
    let neededPriceType: NeededPriceType = 'STANDARD';
    switch (relationType) {
        case 'upsell':
            neededPriceType = 'UPSELL';
            break;
        case 'upgrade':
            neededPriceType = 'UPGRADE';
            break;
        default:
            break;
    }

    return neededPriceType;
};

/**
 * This is a hacky solution - we should migrate loading slug products to react-query instead.
 */
export function hashDependencies(deps: any[]): string {
    return JSON.stringify(deps, (_, val) =>
        isPlainObject(val)
            ? Object.keys(val)
                  .sort()
                  .reduce((result, key) => {
                      result[key] = val[key];
                      return result;
                  }, {} as any)
            : val
    );
}

/**
 * Load all missing products on page load
 */
export default function ProductDataConnector(): null {
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const brxPageContext = useBrxPageContext();

    const { productLoader } = useLoader();

    const dispatch = useAppDispatch();
    const { country, locale } = useShopContext();
    const { activeRelatedProduct: activeProductRelation, slugProduct } = useProduct();

    const lastSlug = useRef<string>();
    const activeProductNeededPriceType = useMemo(
        () => mapBrxRelationTypeToNeededPriceType(activeProductRelation?.relationType),
        [activeProductRelation?.relationType]
    );

    const { ids: productIds, slug: productSlug } = useMemo(() => {
        return BrxUtils.extractProductReferences(brxPageContext, currentLocation.pathname);
    }, [brxPageContext, currentLocation]);

    const onProductBySkuSuccess = (products) => {
        if (products && products.length > 0) {
            navigate(
                {
                    pathname: `p/${products[0]?.slug}`,
                    search: `variant=${productSlug}`,
                },
                { replace: true }
            );
        }
    };

    const {
        refetch: refetchProductsBySku,
        data,
        isSuccess,
        isPending,
    } = useProductsBySku(false, [productSlug ?? EMPTY_STRING], undefined);

    useEffect(() => {
        if (isSuccess && !isPending) {
            onProductBySkuSuccess(data);
        }
    }, [isSuccess, isPending, data]);

    /**
     * Load products by id
     */
    useEffect(() => {
        if (productIds.filter(Boolean).length > 0) {
            productLoader.loadProducts(productIds);
        }
    }, [productIds, productLoader, locale]);

    const useEffectDependencies = hashDependencies([
        productSlug,
        country,
        locale,
        activeProductNeededPriceType,
        activeProductRelation,
    ]);

    /**
     * Load product(s) by slug
     */
    useEffect(() => {
        const didSlugChange = lastSlug.current !== productSlug;
        if (
            productSlug &&
            (didSlugChange || activeProductNeededPriceType !== 'STANDARD' || slugProduct.status === 'unloaded')
        ) {
            if (matchesSkuRegex(productSlug)) {
                refetchProductsBySku();
            } else {
                const isActiveProductRelationSlug = activeProductRelation?.validForSlug === productSlug;

                productLoader.loadProductBySlug(
                    productSlug,
                    isActiveProductRelationSlug ? activeProductNeededPriceType : 'STANDARD',
                    isActiveProductRelationSlug ? activeProductRelation?.relatedProductSku : undefined
                );
            }
        }

        if (productSlug !== lastSlug.current) {
            lastSlug.current = productSlug;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useEffectDependencies]);

    /**
     * Whenever we navigate away from a PDP (= no product slug available) and still have a slugProduct in the store, we remove it.
     * Fixes MS-4002.
     */
    useEffect(() => {
        if (!productSlug && slugProduct.status !== 'unloaded') {
            dispatch(slugProductRemoved());
        }
    }, [dispatch, productSlug, slugProduct.status]);

    // Return null to please react
    return null;
}
