import { usePartner } from '@mediashop/app/hooks/usePartner';
import BloomreachAssets from '@mediashop/base/pattern/atom/BloomreachAssets';
import { CSSProperties, useState } from 'react';
import classNames from 'classnames';
import parseHtml from 'html-react-parser';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router';
import { injectComponent } from '@mediashop/app/component-injector';
import { BaseProps, BrxHeadlines, BrxImageWithAltText, BrxRelationType } from '@mediashop/app/bloomreach/types';
import Button from '@mediashop/base/pattern/atom/Button';
import { Product, Variant } from '@mediashop/app/api/types/ClientProduct';
import BuyButton from '@mediashop/catalog-base/pattern/molecule/BuyButton';
import { useLoader } from '@mediashop/app/store/loader';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { EMPTY_STRING, SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import Headlines from '@mediashop/base/pattern/atom/Headlines';
import { SpecialDealCountdown } from './SpecialDealCountdown';
import SpecialDealPriceBox from '@mediashop/catalog-base/pattern/molecule/SpecialDeal/SpecialDealPriceBox';
import CroppedImage from '@mediashop/base/pattern/atom/CroppedImage';
import { CroppedImageVariant, BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { useBadges } from '@mediashop/catalog-base/hooks/useBadges';
import Badge from '@mediashop/catalog-base/pattern/atom/Badge';
import StarRating from '@mediashop/base/pattern/atom/StarRating';
import useToast from '@mediashop/app/hooks/useToast';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const componentName = 'special-deal';
const productTop = `${componentName}__product-top`;
const imageContainer = `${componentName}__image-container`;
const badgeWrapper = `${componentName}__badge-wrapper`;
const badgeContainer = `${componentName}__badge-container`;

interface SpecialDealProps extends BaseProps {
    customBackgroundColor?: string;
    customFontColor?: string;
    bordercolorHex?: string;
    backgroundColorBottom?: string;
    backgroundColorHexBottom?: string;
    desktopImage?: BrxImageWithAltText;
    endDate?: number;
    hasTransparentHeader?: boolean;
    headlines?: BrxHeadlines;
    mobileImage?: BrxImageWithAltText;
    product: Product;
    productRelationType?: BrxRelationType;
    showCountdown?: boolean;
    showSpecialDealStarRating?: boolean;
    activeVariant?: Variant;
}

// eslint-disable-next-line max-lines-per-function,complexity
function SpecialDeal({
    className,
    customBackgroundColor,
    backgroundColorBottom,
    backgroundColorHexBottom,
    customFontColor,
    bordercolorHex,
    desktopImage,
    endDate,
    hasTransparentHeader = true,
    headlines,
    mobileImage,
    product,
    productRelationType,
    showCountdown = true,
    showSpecialDealStarRating = false,
    activeVariant,
}: SpecialDealProps) {
    const navigate = useNavigate();
    const intl = useIntl();
    const { formatLinkBase } = useReactRouterLink();
    const toast = useToast();

    const { productTileEffect, showReviews } = useChannelSettings();
    const { cartLoader } = useLoader();
    const { partnerId } = usePartner();

    const [isInProgress, setInProgress] = useState(false);
    const [addToCartErrorMessage, setAddToCartErrorMessage] = useState<string>();

    const hasEndDate = Boolean(endDate);
    const dealExpired = new Date(endDate ?? 0) < new Date();

    const firstVariant = product?.variants?.length
        ? (product.variants.find((variant) => variant.price) ?? product?.variants[0])
        : undefined;

    const { detailBadges, bottomBadges, topBadges } = useBadges({ product, variant: firstVariant });

    const productDescription = activeVariant?.attributes?.claim || firstVariant?.attributes?.claim;

    const opaqueBackgroundColor =
        hasTransparentHeader && customBackgroundColor?.length === 7
            ? `${customBackgroundColor}cc`
            : customBackgroundColor;

    const defaultBackgroundColor = hasTransparentHeader ? 'var(--color-red-opacity-80)' : 'var(--color-red)';

    const freeExtrasBadge = bottomBadges.find((badge) => badge.type === 'GRATISZUBEHOER');

    const activeVariants = product?.variants?.filter((variant) => variant.price !== undefined);

    const stars = product?.variants[0]?.attributes?.ratingValueLocalized ?? 0;
    const reviewsAvailable = showReviews && stars > 0 && showSpecialDealStarRating;
    const showFrom = !activeVariant && product?.variants && product?.variants.length > 1;

    /**
     * Add article to cart and redirect to cart.
     */
    const handleAddToCartClick = async (quantity: number) => {
        if (firstVariant) {
            setInProgress(true);
            setAddToCartErrorMessage(undefined);
            try {
                const { exceededMaxQuantity } = await cartLoader.addLineItem({
                    partner: partnerId,
                    quantity,
                    variant: activeVariant ?? firstVariant,
                });
                if (exceededMaxQuantity) {
                    const errorMessage = intl.formatMessage(
                        { id: 'product.exceededMaxOrderQuantity' },
                        {
                            amount: `${
                                activeVariant?.attributes?.maxOrderQuantity ?? firstVariant.attributes?.maxOrderQuantity
                            }`,
                        }
                    );
                    toast.error(errorMessage);
                    setAddToCartErrorMessage(errorMessage);
                }
            } catch {
                const addToCartErrorMessage = intl.formatMessage({ id: 'product.cantAddToCart' });
                toast.error(addToCartErrorMessage);
                setAddToCartErrorMessage(addToCartErrorMessage);
            }
            setInProgress(false);
        }
    };

    const goToProduct = (event) => {
        event.preventDefault();
        navigate({
            pathname: `p/${product?.slug}`,
            search: activeVariant?.sku ? `variant=${activeVariant.sku}` : '',
        });
    };

    const containerStyle: CSSProperties = {};
    if (bordercolorHex) {
        containerStyle.borderColor = bordercolorHex;
    }

    if (backgroundColorHexBottom) {
        containerStyle.backgroundColor = backgroundColorHexBottom;
    }

    return (
        <div
            onClick={goToProduct}
            className={classNames(
                componentName,
                className,
                backgroundColorBottom ? `background--${backgroundColorBottom}` : 'background--default',
                { [`${componentName}--hover-effect-zoom`]: productTileEffect === 'Zoom' }
            )}
            style={containerStyle}
        >
            {/** Product image */}
            <div className={productTop}>
                <div className={imageContainer}>
                    {desktopImage ? (
                        <BloomreachAssets
                            className={componentName}
                            altText={desktopImage.altText ?? EMPTY_STRING}
                            desktopImage={desktopImage}
                            mobileImage={mobileImage}
                            variant={BrXImageVariant.SpecialDeal}
                        />
                    ) : (
                        <CroppedImage
                            altText={product.name}
                            className={`${imageContainer}__desktop`}
                            src={product.imageUri}
                            variant={CroppedImageVariant.SpecialDeal}
                        />
                    )}

                    <div className={badgeWrapper}>
                        <div className={`${badgeContainer} ${badgeContainer}--bottom`}>
                            {freeExtrasBadge ? (
                                <Badge
                                    key={freeExtrasBadge.badgeProps.text}
                                    {...freeExtrasBadge.badgeProps}
                                    size="small"
                                />
                            ) : (
                                SKIP_RENDER
                            )}
                        </div>
                    </div>
                </div>

                {/** Headlines, Countdown & Price */}
                <div className={`${componentName}__specials-container`}>
                    {headlines && (
                        <div
                            className={classNames(`${componentName}__specials-top`)}
                            style={{
                                background: customBackgroundColor ? opaqueBackgroundColor : defaultBackgroundColor,
                            }}
                        >
                            <Headlines
                                className={`${componentName}__specials-headline`}
                                {...headlines}
                                headlineStyle="H3"
                                textColor={customFontColor}
                            />
                        </div>
                    )}

                    {showCountdown && hasEndDate ? (
                        <div
                            className={classNames(`${componentName}__specials-bottom`, {
                                [`${componentName}__specials-bottom--no-headlines`]: !headlines,
                            })}
                        >
                            <SpecialDealCountdown
                                date={endDate!}
                                className={`${componentName}__specials-countdown`}
                                textColor={customFontColor}
                            />
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            </div>

            {/** Product details */}
            <div className={`${componentName}__details-container`}>
                {/** Title / Name */}
                <div className={`${componentName}__title`} title={activeVariant?.attributes.name ?? product?.name}>
                    <Link onClick={goToProduct} to={formatLinkBase(`p/${product?.slug}/`)}>
                        {activeVariant?.attributes.name ?? product?.name}
                    </Link>
                </div>

                {/** Description */}
                {productDescription ? (
                    <div className={`${componentName}__description`}>{parseHtml(productDescription)}</div>
                ) : (
                    SKIP_RENDER
                )}

                {/** Ratings */}
                {reviewsAvailable ? (
                    <div className={`${componentName}__reviews-holder`}>
                        <StarRating
                            className={`${componentName}__rating`}
                            rating={stars}
                            ratingText={
                                <span className={`${componentName}__review-count`}>
                                    <FormattedNumber
                                        value={
                                            activeVariant?.attributes?.ratingCountLocalized ??
                                            product?.variants[0]?.attributes?.ratingCountLocalized
                                        }
                                    />
                                </span>
                            }
                        />
                    </div>
                ) : (
                    SKIP_RENDER
                )}

                {/** Special deal price */}
                {!endDate || !dealExpired ? (
                    <SpecialDealPriceBox
                        className={componentName}
                        activeVariant={activeVariant ?? firstVariant!}
                        showCheapestPrice={!activeVariant}
                        showFrom={showFrom}
                        showSavings={false}
                        hasVariants={activeVariants.length > 1}
                        detailBadges={detailBadges}
                        topBadges={topBadges}
                    />
                ) : (
                    SKIP_RENDER
                )}

                {/** Add to cart button */}
                <div className={`${componentName}__cta-btn`} onClick={(event) => event.stopPropagation()}>
                    {activeVariants.length > 1 && showFrom ? (
                        <Button
                            style="tertiary"
                            className={`${componentName}__cta-btn-details`}
                            onClick={goToProduct}
                            fullWidth
                        >
                            <FormattedMessage id="product.showDetails" />
                        </Button>
                    ) : (
                        <BuyButton
                            className={`${componentName}__cta-btn-cart`}
                            product={product}
                            activeVariant={activeVariant ?? firstVariant}
                            onClick={handleAddToCartClick}
                            textId="product.addToCart"
                            isLoading={isInProgress}
                            goToCart={false}
                            hidePreCheckoutFlyout={isInProgress}
                            productRelationType={productRelationType}
                            addToCartErrorMessage={addToCartErrorMessage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default injectComponent('pattern.molecule.SpecialDeal', SpecialDeal, 'catalog-base');
