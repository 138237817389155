import useRoute from '../../hooks/useRoute';
import { useLocation } from 'react-router';

/**
 * Hook determining whether to create a order based on the current page.
 */
export default function useShouldCreateOrder(): boolean {
    const currentLocation = useLocation();
    const createOrderRoute = useRoute('Frontend.Master.OrderCreate');
    return currentLocation.pathname.includes(createOrderRoute);
}
