import { Helmet } from 'react-helmet-async';
import { useShopContext } from '../hooks/useShopContext';
import { useHrefLang } from '../hooks/useHrefLang';

export function Hreflang() {
    const {
        location: { href },
    } = useShopContext();

    const hrefLangUrlMap = useHrefLang();

    if (href.includes('/p/') || href.includes('/c/')) {
        return null;
    }

    return (
        <Helmet>
            {Object.entries(hrefLangUrlMap).map(([locale, pathName]) => (
                <link key={locale} rel="alternate" hrefLang={locale} href={pathName} />
            ))}
        </Helmet>
    );
}
