import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { injectComponent } from '@mediashop/app/component-injector';
import Icon from '../Icon';
import { SelectBoxProps } from './index';
import * as icons from '@mediashop/base/icons';
import { useOutsideClicked } from '@mediashop/app/hooks/useOutsideClicked';

const allIcons = icons.default;

const componentName = 'select-box';

function SelectBox({
    className,
    defaultValue,
    onChange,
    options,
    selectEnabled = true,
}: SelectBoxProps): JSX.Element | null {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useOutsideClicked(wrapperRef, () => setIsOpen(false));

    const handleSelect = (option) => {
        onChange(option);
        setSelectedValue(option);
        setIsOpen(false);
    };

    return (
        <div
            className={classNames(componentName, {
                [`${className}__${componentName}`]: className,
            })}
            ref={wrapperRef}
        >
            <div
                data-testid="select-box"
                className={classNames(`${componentName}__selected-value-wrap`, {
                    [`${componentName}__selected-value-wrap--disabled`]: !selectEnabled,
                })}
                onClick={selectEnabled ? () => setIsOpen(!isOpen) : undefined}
            >
                <div className={`${componentName}__selected-value`}>
                    {selectedValue.iconName ? (
                        <img alt="Flag" height="18" width="25" src={allIcons[selectedValue.iconName]} />
                    ) : (
                        SKIP_RENDER
                    )}
                    {selectedValue.label ? <span>{selectedValue.label}</span> : SKIP_RENDER}
                </div>
                <div className={`${componentName}__selected-value-icon`}>
                    <Icon name="ChevronDown" />
                </div>
            </div>
            {isOpen ? (
                <div className={`${componentName}__options`}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={classNames(`${componentName}__options-item`, {
                                [`${componentName}__options-item--selected`]: selectedValue.value === option.value,
                            })}
                            onClick={() => handleSelect(option)}
                        >
                            {option.iconName ? (
                                <img alt="Flag" height="18" width="25" src={allIcons[option.iconName]} />
                            ) : (
                                SKIP_RENDER
                            )}
                            {option.label ? <span>{option.label}</span> : SKIP_RENDER}
                        </div>
                    ))}
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
}

export default injectComponent('pattern.molecule.SelectBox', SelectBox);
