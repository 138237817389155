import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import Icon from '@mediashop/base/pattern/atom/Icon';

const componentName = 'cart-empty-state';

function CartEmptyState(): JSX.Element {
    const { formatLinkBase } = useReactRouterLink();
    const homeLink = formatLinkBase(EMPTY_STRING);

    return (
        <div className={`${componentName}__products`}>
            <h1 className={`${componentName}__headline`}>
                <FormattedMessage id="cart.emptyCart" />
            </h1>
            <div className={`${componentName}__text`}>
                <FormattedMessage id="cart.emptyCartMessage" />
            </div>
            <div className={`${componentName}__icon`}>
                <Icon name="Cart" />
            </div>
            <div className={`${componentName}__button`}>
                <Link to={homeLink} className={`${componentName}__empty-link`}>
                    <FormattedMessage id="cart.emptyCartButtonMessage" />
                </Link>
            </div>
            <div className={`${componentName}__subline`}>
                <FormattedMessage id="cart.articlesWithCount" values={{ count: 0 }} />
            </div>
        </div>
    );
}

export default CartEmptyState;
