import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import { SKIP_RENDER } from './constants/semanticConstants';

const RouterErrorBoundary = () => {
    const routerError = useRouteError();
    const [showDetails, setShowDetails] = useState(false);

    let errorStack: string | undefined;

    if (isRouteErrorResponse(routerError)) {
        errorStack = routerError.statusText;
    } else if (routerError instanceof Error) {
        errorStack = routerError.stack;
    }

    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div style={{ margin: 16 }}>
            <h1>
                <FormattedMessage id="routerErrorBoundary.title" />
            </h1>
            <p>
                <FormattedMessage id="routerErrorBoundary.infoText" />
            </p>
            <button onClick={refreshPage} style={{ marginBottom: 16 }}>
                <FormattedMessage id="routerErrorBoundary.buttonText" />
            </button>

            {errorStack ? (
                <div style={{ border: '1px solid #f0f0f0', background: '#fafafa' }}>
                    <div
                        style={{
                            padding: '8px 16px',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        onClick={() => setShowDetails(!showDetails)}
                    >
                        <FormattedMessage id="routerErrorBoundary.stacktraceTitle" />
                    </div>
                    <pre
                        style={{
                            display: showDetails ? 'block' : 'none',
                            margin: 0,
                            padding: 16,
                            borderTop: '1px solid #f0f0f0',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                        }}
                    >
                        {errorStack}
                    </pre>
                </div>
            ) : (
                SKIP_RENDER
            )}
        </div>
    );
};

export default RouterErrorBoundary;
