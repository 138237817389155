import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { usePageViewed } from '../../analytics/hooks/usePageViewed';
import { useAppDispatch } from '../../store/hooks/hooks';
import { pageReceived } from '../../store/reducer/page';
import { useBrxPageContext } from '../useBrxPageContext';
import { useBrxComponentContext } from '../useBrxComponentContext';
import { EMPTY_STRING } from '../../constants/semanticConstants';
import { BrxUtils } from '../utils/BrxUtils';

/**
 * Page data connector
 */
export default function PageDataConnector(): null {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const brxComponent = useBrxComponentContext();
    const brxPage = useBrxPageContext();

    const dispatchPageViewed = usePageViewed();

    useEffect(() => {
        const pageType = brxComponent.getName() ?? EMPTY_STRING;
        const pagePath = `${location.pathname ?? ''}${location.search ?? ''}`;

        const seoComponent = BrxUtils.getComponentByClass(brxPage, 'tv.mediashop.components.SEOComponent');

        const seoProperties = seoComponent?.getProperties() ?? {};
        const pageTitle = seoProperties?.title ?? EMPTY_STRING;

        dispatch(pageReceived({ page: { title: pageTitle, pageType, pathName: pagePath } }));

        // Pageview event for dataLayer:
        dispatchPageViewed({ pageTitle, pageType, pageUri: pagePath });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brxComponent, brxPage, dispatch]);

    // Return null to please react
    return null;
}
