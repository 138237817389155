import { Category } from '@mediashop/app/api/types/ClientCategory';
import { findCategoryIdBySlug } from '@mediashop/app/bloomreach/product/determineProductsCategory';
import { useBrxPageContext } from '@mediashop/app/bloomreach/useBrxPageContext';
import { useChannelSettings } from '@mediashop/app/bloomreach/useChannelSettings';
import { BrxUtils } from '@mediashop/app/bloomreach/utils/BrxUtils';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import findCategoryById from '@mediashop/app/helper/findCategoryById';
import { useCategory } from '@mediashop/app/hooks/useCategory';
import { useProject } from '@mediashop/app/hooks/useProject';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import useSiteName from '@mediashop/app/hooks/useSiteName';
import { useCategories } from '@mediashop/app/queries/categories/useCategories';
import split from 'lodash/split';
import map from 'lodash/map';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { helmetJsonLdProp } from 'react-schemaorg';
import { WithContext, BreadcrumbList } from 'schema-dts';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import { formatHrefLangLink } from '../../../utils/formatHrefLangLink';
import { hyphenToUnderscore } from '../../../utils/hyphenToUnderscore';

const URL_PARAM_PAGE = 'page';
const URL_PARAM_SUBCATEGORY = 'subcategory';

const generateCategoryBreadcrumbsSchema = (
    breadcrumbs: { key: string; label: string }[],
    href: string,
    shopName: string
): WithContext<BreadcrumbList> => {
    const shopUrl = href.slice(0, href.indexOf('/c/'));
    const categoriesArray = href.slice(href.indexOf('c/') + 2).split('/');

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                name: shopName,
                item: shopUrl,
            },
            {
                '@type': 'ListItem',
                position: 2,
                name: breadcrumbs[0]?.label,
                item: `${shopUrl}/c/${categoriesArray[0]}`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: breadcrumbs.length > 1 ? breadcrumbs[1].label : undefined,
                item: categoriesArray.length > 1 ? `${shopUrl}/c/${categoriesArray.join('/')}` : undefined,
            },
        ],
    };
};

const getHrefWithPage = (href: string, page: number) => {
    const urlWithoutQueryParams = href.split('?')[0];
    return page === 1 ? urlWithoutQueryParams : `${urlWithoutQueryParams}?page=${page}`;
};

const getParentCategory = (categories: Category[], subcategorySlug: string) => {
    if (subcategorySlug) {
        const categoryId = findCategoryIdBySlug(categories, subcategorySlug);
        const subcategory = findCategoryById(categories, categoryId);
        if (subcategory) {
            return findCategoryById(categories, subcategory.parentId);
        }
    }
    return undefined;
};

function CategorySEO() {
    const { data: categories = [] } = useCategories();
    const { selectedCategoryId } = useCategory();

    const { search, pathname } = useLocation();

    const selectedSubcategorySlug = useMemo(
        () => new URLSearchParams(search).get(URL_PARAM_SUBCATEGORY) ?? '',
        [search]
    );

    const category = useMemo(() => findCategoryById(categories, selectedCategoryId), [categories, selectedCategoryId]);
    const parentCategory = useMemo(
        () => getParentCategory(categories, selectedSubcategorySlug) ?? category,
        [categories, selectedSubcategorySlug, category]
    );
    const page = useMemo(() => {
        const pageString = new URLSearchParams(search).get(URL_PARAM_PAGE) ?? '1';
        return parseInt(pageString, 10);
    }, [search]);

    const { formatMessage } = useIntl();

    const brxPage = useBrxPageContext();
    const seoComponent = BrxUtils.getComponentByClass(brxPage, 'tv.mediashop.components.SEOComponent');
    const seoParamsInfo = seoComponent?.getProperties() ?? {};

    const { seoMetaTitle } = useChannelSettings();
    const shopName = useSiteName();
    const { takeLanguageFromUrl, basePath } = useProject();
    const {
        route,
        locale: currentLocale,
        location: { origin, hostname, href },
    } = useShopContext();

    const routeArray = split((route as string).substring(1), '/');
    const routes = routeArray.slice(routeArray.findIndex((el) => el === 'c') + 1);

    const { flatBreadcrumbIndex: breadcrumbIndex } = useBreadcrumbs();

    if (!pathname.includes('/c/')) {
        return SKIP_RENDER;
    }

    const breadcrumbs = map(routes, (route) => ({
        key: hyphenToUnderscore(route),
        label: breadcrumbIndex[hyphenToUnderscore(route)]?.name,
    }));

    // Generate page title
    const pageTitle = formatMessage(
        { id: 'productListing.title' },
        {
            categoryName: parentCategory?.metaTitle ?? parentCategory?.name,
            shopName: seoMetaTitle ?? hostname,
            count: page,
        }
    );

    const filteredSlugs = Object.entries(category?.slugAllLocales ?? {})?.filter(
        ([locale]) => locale !== currentLocale
    );

    const categoryBreadcrumbs = generateCategoryBreadcrumbsSchema(breadcrumbs, href, shopName);

    const hrefWithPageParameter = getHrefWithPage(href, page);

    const renderMetaDescription = () => {
        if (seoParamsInfo?.description) {
            return <meta name="description" content={seoParamsInfo.description} />;
        } else if (parentCategory?.metaDescription) {
            return <meta name="description" content={parentCategory.metaDescription} />;
        }
        return SKIP_RENDER;
    };

    const renderHrefLang = () => {
        return filteredSlugs?.map(([locale, slug]) => {
            let categoryPath = slug;
            if (category?.parentId) {
                const ancestor = category.ancestors?.find(
                    (ancestor: { id: string }) => ancestor.id === category.parentId
                );

                if (ancestor?.custom?.urlSlug?.[locale]) {
                    categoryPath = `${ancestor?.custom?.urlSlug?.[locale]}/${categoryPath}`;
                }
            }

            return (
                <link
                    key={locale}
                    rel="alternate"
                    hrefLang={locale.toLowerCase()}
                    href={formatHrefLangLink({
                        origin,
                        basePath,
                        locale,
                        path: `c/${categoryPath}`,
                        takeLanguageFromUrl,
                    })}
                />
            );
        });
    };

    return (
        <Helmet
            script={
                /** Schema tags */
                categoryBreadcrumbs ? [helmetJsonLdProp<BreadcrumbList>(categoryBreadcrumbs)] : undefined
            }
        >
            {/* Page title */}
            <title>{seoParamsInfo?.title ? seoParamsInfo.title : pageTitle}</title>

            {/* Canonical and prev/next links */}
            <link rel="canonical" href={hrefWithPageParameter} />

            {/* Category meta description */}
            {renderMetaDescription()}

            {/* Href-lang */}
            {renderHrefLang()}
        </Helmet>
    );
}

export default CategorySEO;
