import useIsPreview from './useIsPreview';
import { useLocation } from 'react-router';
import { NOT_FOUND_PATH } from '../constants/internalUrls';
import { useNavigate } from './useNavigate';

/**
 * Redirect to the not-found page of a shop.
 * When the page is in Bloomreach preview, we prevent the redirect in order to not break the preview.
 */
export default function useRedirectToNotFound(): () => void {
    const isPreview = useIsPreview();
    const location = useLocation();
    const navigate = useNavigate();

    return () => {
        if (!isPreview && !location.pathname.includes(NOT_FOUND_PATH)) {
            setTimeout(() => {
                navigate(NOT_FOUND_PATH, { replace: true });
            }, 0);
        }
    };
}
