import { useCart } from '@mediashop/app/hooks/useCart';
import { usePartner } from '@mediashop/app/hooks/usePartner';
import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { VoucherCheckModal } from './Modal';
import { getDiscountFromLineItems, getTotalPriceDiscounts } from '@mediashop/base/utils/discounts';
import NonStackableDiscountsModal from '@mediashop/base/pattern/molecule/NonStackableDiscountsModal';
import { useAddDiscountCode } from '@mediashop/app/hooks/api/useAddDiscountCode';
import { ApiError } from '@mediashop/app/api/ApiError';
import useToast from '@mediashop/app/hooks/useToast';
import { useNavigate } from '@mediashop/app/hooks/useNavigate';

const QUERY_PARAM_VOUCHER = 'gs';
const QUERY_PARAM_PARTNER = 'partner';

export default function VoucherCheck(): JSX.Element | null {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const cartState = useCart();
    const { partnerId } = usePartner();
    const { currency } = useShopContext();

    const voucherCode = useMemo(() => new URLSearchParams(location.search).get(QUERY_PARAM_VOUCHER), [location]);

    const discountCode = useMemo(
        () => cartState.cart?.discountCodes?.find((code) => code.discountCode?.code === voucherCode)?.discountCode,
        [cartState, voucherCode]
    );

    const lineItems = cartState.cart?.lineItems;
    const discountCodes = cartState.cart?.discountCodes;

    const discounts = useMemo(
        () => Object.values(getDiscountFromLineItems(lineItems ?? [], discountCodes ?? [], currency)),
        [lineItems, discountCodes, currency]
    );

    const totalPriceDiscounts = useMemo(
        () => getTotalPriceDiscounts(discountCodes ?? [], cartState.cart?.discountOnTotalPrice),
        [cartState, discountCodes]
    );

    const nonStackableDiscounts = [
        ...discounts.filter((discount) => discount.stackingMode === 'StopAfterThisDiscount'),
        ...totalPriceDiscounts.filter((discount) => discount.stackingMode === 'StopAfterThisDiscount'),
    ];

    const applicableDiscount = discounts?.find((discount) => discount.code === discountCode?.code);

    const removeVoucherFromUrl = useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete(QUERY_PARAM_VOUCHER);
        queryParams.delete(QUERY_PARAM_PARTNER);
        navigate(`${location.pathname}?${queryParams.toString()}`);
    }, [navigate, location]);

    const handleAddDiscountCodeError = (code: ApiError) => {
        const { error } = code;

        if (error?.error === 'DISCOUNT_ALREADY_PRESENT') {
            toast.error(intl.formatMessage({ id: 'cart.voucherCodeErrorCodes.alreadyPresent' }, { code: voucherCode }));
        } else if (error?.error === 'DISCOUNT_CODE_NOT_APPLICABLE') {
            toast.error(intl.formatMessage({ id: 'cart.voucherCodeErrorCodes.nonApplicable' }, { code: voucherCode }));
        }

        removeVoucherFromUrl();
    };

    const handleAddDiscountCodeSuccess = () => {
        setShowSuccessModal(true);
    };

    const { mutate: addDiscountCode } = useAddDiscountCode({
        onError: handleAddDiscountCodeError,
        onSuccess: handleAddDiscountCodeSuccess,
    });

    useEffect(() => {
        // Only apply discount code when it isn´t already applied
        if (voucherCode && !discountCode && cartState.status === 'succeeded') {
            addDiscountCode({ discountCode: voucherCode, partnerId });
        }
    }, [addDiscountCode, voucherCode, partnerId, discountCode, cartState.status]);

    const handleModalClose = () => {
        setShowSuccessModal(false);
        removeVoucherFromUrl();
    };

    return showSuccessModal && discountCode && nonStackableDiscounts && nonStackableDiscounts.length <= 1 ? (
        <VoucherCheckModal onModalClose={handleModalClose} discount={applicableDiscount} discountCode={discountCode} />
    ) : (
        <NonStackableDiscountsModal
            isOpen={nonStackableDiscounts && nonStackableDiscounts.length > 1}
            nonStackableDiscounts={nonStackableDiscounts}
            onCloseModal={removeVoucherFromUrl}
        />
    );
}
