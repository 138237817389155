import { useProject } from './useProject';
import { useShopContext } from './useShopContext';

export const useHrefLang = () => {
    const { locales, shippingCountries: countries, takeLanguageFromUrl } = useProject();
    const {
        country,
        locale,
        location: { href },
    } = useShopContext();

    if (!takeLanguageFromUrl) {
        return {};
    }

    const countryLanguageUrlSegment = `/${country}/${locale.split('-')[0]}`.toLowerCase();

    const hreflangLocales = countries.reduce((allLocales, country) => {
        const countryLocales = locales.map((locale) => `${locale.split('-')[0]}-${country}`.toLowerCase());

        return [...allLocales, ...countryLocales];
    }, []);

    const hrefLangUrlMap = hreflangLocales.reduce<Record<string, string>>((map, hrefLangLocale) => {
        const [localeLanguage, localeCountry] = hrefLangLocale.split('-');
        const pathName = href.replace(countryLanguageUrlSegment, `/${localeCountry}/${localeLanguage}`);
        return { ...map, [hrefLangLocale]: pathName };
    }, {});

    return hrefLangUrlMap;
};
