import { createQueryKeys } from '@lukemorales/query-key-factory';
import { SearchByAttributeFilter } from '../../api/types/ProductQueries';

export const search = createQueryKeys('search', {
    searchProducts: (searchTerm: string, page: number, country: string, limit: number) => ({
        queryKey: [searchTerm, page, country, limit],
    }),

    autoSuggest: (searchTerm: string, country: string, currency: string, locale: string, limit: number) => ({
        queryKey: [searchTerm, country, currency, locale, limit],
    }),

    searchByAttribute: (
        filters: Array<SearchByAttributeFilter>,
        country: string,
        currency: string,
        locale: string,
        offset: number,
        limit: number
    ) => ({
        queryKey: [filters, country, currency, locale, offset, limit],
    }),
});
